import _ from 'lodash'
import tagHelper from '../../components/lib/tagHelper.js'

const eventDataPrep = store => {
    store.encodeOwnerKey = function (ownerKey) {
        return ownerKey.replace('::', "__")
    },
        store.decodeOwnerKey = function (ownerKey) {
            return ownerKey.replace("__", '::')
        },
        store.splitKey = function (ownerKey) {
            var key = { 'id': null, 'objectTypeID': null, 'objectType': null }
            var parts = ownerKey.split("::")
            if (parts.length > 1) {
                key.objectType = parts[0]
                key.id = parts[1]
                //console.log(parts[0], store.state.search.searchMaps.maps)
                key.objectTypeID = store.state.search.searchMaps.maps.ownerTypeRevMap[parts[0]]
            }
            return key;
        },
        store.prepareEvents = function (opt) {
            if (!opt.events) return []
            var events = []
            var sourceEvents = _.cloneDeep(opt.events)
            var hitsSorted = _.sortBy(sourceEvents, function (e) {
                var date = new Date(e.date);
                if (!isNaN(date.valueOf()))
                    return date;
                else
                    return -1;
            }).reverse();

            hitsSorted.forEach((event) => {
                if (!this.eventSettings.ignoredEvents.includes(event.eventType)) {
                    if (opt.eventSource == 'events' && event.id) {
                        events.push(this.prepareEvent(event, opt.obj))
                    }
                    else if (opt.eventSource == 'comments' && event.id) {
                        events.push(this.prepareCommentEvent(event, opt.obj))
                    }
                    else if (opt.eventSource == 'emails' && event.id) {
                        events.push(this.prepareEmailEvent(event, opt.obj))
                    }
                    else if (opt.eventSource == 'tagsflags') {
                        events.push(this.prepareFlagTagEvent(event, opt.obj))
                    }
                    else if (opt.eventSource == 'acquisitions' && event.id) {
                        events.push(this.prepareAcqEvent(event, opt.obj))
                    }
                    else if (opt.eventSource == 'articles' && event.id) {
                        events.push(this.prepareArticleEvent(event, opt.obj))
                    }
                }
            })
            if (opt.eventSource == 'events' && events.length > 0 && !opt.fetchedEvents) {
                events[0].isQueryEvent = true
            }

            return events
        },
        store.insertEvents = function (toInsert, events) {
            var appended = events && _.isArray(events) ? _.cloneDeep(events) : []
            if (toInsert != null && _.isArray(toInsert) && toInsert.length > 0) {
                toInsert.forEach((ev) => { appended.push(ev) })
                appended.sort((a, b) => {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.date) - new Date(a.date);
                })
            }
            return appended
        },
        store.prepareEvent = function (event, obj) {

            //var event = _.cloneDeep(eventSrc)
            //get the render map to see what we're going to render
            var eventTypeRenderMap = store.state.search.eventTypeRenderMap
            var renderRules = eventTypeRenderMap[event.eventType]
            if (!renderRules) {
                renderRules = eventTypeRenderMap["-1"];
            }

            //set the base fields
            event.linkURL = '/eventlog/Details/'
            event.htmlBody = false

            var eventTypeMap = store.state.search.searchMaps.maps.eventTypeMap
            event.eventTypeClass = this.eventCategoryClass(eventTypeMap[event.eventType] ? eventTypeMap[event.eventType] : '')

            var eventTypeCategoryMap = store.state.search.searchMaps.maps.eventTypeCategoryMap
            event.eventCategory = eventTypeCategoryMap[event.eventType] ? eventTypeCategoryMap[event.eventType] : 'Unknown';
            event.eventCategoryClass = this.eventCategoryClass(eventTypeCategoryMap[event.eventType] ? eventTypeCategoryMap[event.eventType] : '')

            event.weight = store.state.search.searchMaps.maps.eventTypeWeightMap[event.eventType]

            event.combineEvent = renderRules.combineEvent

            if (renderRules.renderSource) {
                event.name = this.capitalizeFirst(event.ownerType) + event.name

                if (obj.objectType == event.ownerType)
                    event.description = ""
            }

            if (renderRules.renderDescription == 0) {
                event.description = "";
            }
            else {
                event.description = event.description ? event.description : "";
                if (event.description.startsWith("{")) { //handle json descriptions
                    try {
                        var descObj = JSON.parse(event.description);
                        switch (event.eventType) {
                            case 15: //ndr
                                event.htmlBody = true;
                                event.description = "<i>From:</i> " + descObj.emailAddress + "<br>"
                                    + "<i>Reason:</i> " + descObj.reason.reason + " (" + descObj.reason.reasonTypeID + "). <i>Parsed:</i> " + descObj.reason.parse + ". "
                                    + "<i>Bad Address:</i> " + (descObj.reason.addressIsBad ? 'Yes' : 'No') + ". <i>Fatal Error:</i> " + (descObj.reason.fatalError ? 'Yes' : 'No');
                                break;
                            default:
                                event.description = 'Missing JSON render rule: ' + event.description;
                                break;
                        }
                    }
                    catch {
                        event.description = 'Could not parse: ' + event.description
                    }
                }
            }

            if (renderRules.renderActors || renderRules.renderRelated) {
                if (event.eventRelationships) {
                    var relationships = event.eventRelationships

                    var relationshipRules = renderRules.relationshipRules
                    var cleanRelationships = relationships.filter((r) => { return !relationshipRules[r.objectType] || !relationshipRules[r.objectType].skip })
                    cleanRelationships.forEach((r) => {
                        //if there are no explicit rules for this item, just keep it the same
                        if (relationshipRules && relationshipRules[r.objectType]) {
                            //rename this item if there's a rename rule
                            r.name = relationshipRules[r.objectType].newName ? relationshipRules[r.objectType].newName : r.name
                        }
                    })

                    event.eventActors = []
                    if (renderRules.renderActors) {
                        var actors = cleanRelationships.filter((r) => {
                            return (r.isActor && !(r.id == obj.id && r.objectType == obj.objectType))
                        })
                        event.eventActors = actors.map((a) => {
                            return this.updateUserName(a)
                        })
                    }
                    event.eventRelated = []
                    if (renderRules.renderRelated) {
                        event.eventRelated = cleanRelationships.filter((r) => { return r.isActor != true && !this.eventSettings.ignoreRelatedTypes.includes(r.objectType) && !(r.id == obj.id && r.objectType == obj.objectType) })
                    }

                }
            }
            return event
        },
        store.prepareAcqEvent = function (event, obj) {
            event = this.prepareEvent(event, obj)
            event.linkURL = '/Acquisition/Details/'
            return event
        },
        store.prepareArticleEvent = function (event, obj) {
            event = this.prepareEvent(event, obj)

            console.log('article', obj)
            event.linkURL = '/Acquisition/Details/' + obj.id + '?articleid='
            event.htmlBody = true
            return event
        },
        store.prepareFlagTagEvent = function (event, obj) {
            event.id = -1;
            event = this.prepareEvent(event, obj)

            var flagMap = this.eventSettings.flagMap
            event.name = flagMap && flagMap[event.biCategory] ? flagMap[event.biCategory] : event.name
            event.name = event.name.charAt(0) == '#' || event.name.charAt(0) == '@' ? event.name : '#' + event.name
            event.userName = this.updateUserName({ id: event.userID, name: event.userName }).name
            event.linkURL = "";

            //figure out the links for the flags and fix the ones that need to be fixed
            if (event.biCategory) {
                event.eventCategory = 'Flags';
                event.eventCategoryClass = 'outreach';
                if (event.commentID) {
                    event.id = event.commentID;
                    event.linkURL = '/Comment/Edit/';
                }

                switch (event.biCategory) {
                    //thumbs
                    case 256:
                        var thumb = ""
                        if (event.value == 'ThumbUp')
                            thumb = ":Up"
                        else if (event.value == 'ThumbDown')
                            thumb = ':Down'

                        event.name = event.name + thumb
                        break;
                    //bookmark
                    case 2048:
                        event.name = "#Bookmark"
                        //link to the query if there's one
                        if (event.targetID && event.targetTypeID == 18) {
                            event.id = event.targetID;
                            event.linkURL = '/search?queryID=';
                            event.name = event.name + ' (Search)'
                        }
                        //or to the details page
                        else if (event.ownerID && event.ownerTypeID) {
                            var ownerType = this.state && this.state.search && this.state.search.searchMaps && this.state.search.searchMaps.maps && this.state.search.searchMaps.maps.ownerTypeMap ? this.state.search.searchMaps.maps.ownerTypeMap[event.ownerTypeID] : '';
                            if (ownerType) {
                                event.id = event.ownerID;
                                event.linkURL = '/' + ownerType + '/details/';

                            }
                        }
                        break;
                    default:
                        break;
                }
            }

            //figure out the link for the tags. the isTag check ensures we will only convert the data that's been massaged by tagToFlagEvent
            else if (event.isTag) {
                event.eventCategory = 'Tags';
                event.eventCategoryClass = 'qualification';
                if (event.type == 'hashtag') {
                    //event.eventCategory = 'HashTags';
                    event.description = '';
                    //objects in .tagValues have a {tagName, tagID, tagType} structure, needed by the tagHelper
                    _.each(event.tagValues, function (val) {
                        var tag = ""
                        if (val.tagID) tag = tagHelper.tagUtils.buildAtTagSpan(val.tagName, val.tagID, val.tagType)
                        else if (val.tagName.startsWith('#')) tag = tagHelper.tagUtils.buildHashTagSpan(val.tagName)

                        if (tag) {
                            event.description = (event.description ? event.description + ', ' : event.description) + tag;
                            event.htmlBody = true
                        }
                        else
                            event.description = (event.description ? event.description + ', ' : event.description) + (val.tagID ? '@' + val.tagName + '' : val.tagName + '')
                    });
                    //objects in .values have a {value, ownerID, ownerTypeID} structure, needed by the search engine
                    event.linkURL = "/search?docType=Company&tags=" + encodeURIComponent(JSON.stringify({ name: event.name, values: event.values }));
                    event.id = "";
                }
                else if (event.type == 'attag') {
                    if (event.tagID > 0) {
                        //event.eventCategory = '@Tags';
                        var objTypeToString = store.getters['search/objectTypeIdToStringMap'];
                        event.linkURL = "/" + (objTypeToString[event.tagType] ? objTypeToString[event.tagType] : 'company') + "/Details/";
                        event.id = event.tagID
                    }
                    else {
                        event.linkURL = "";
                        event.id = -1;
                    }
                }
            }
            //no link if its neither a tag or a flag
            else {
                event.linkURL = "";
                event.id = -1;
            }

            event.eventActors = event.userID && event.userName ? [{ id: event.userID, name: event.userName, objectType: 'user' }] : []
            event.objectType = "flag"

            return event
        },
        store.prepareCommentEvent = function (event, obj) {
            event = this.prepareEvent(event, obj)
            var commentTypes = store.state.comments.commentTypes
            var commentType = _.find(commentTypes, function (c) { return c.id == event.commentType });

            event.name = commentType ? commentType.entry : 'Unknown'
            event.eventCategoryClass = commentType ? this.eventCategoryClass(commentType.entry) : this.eventCategoryClass('Unknown')
            event.eventCategory = commentType ? commentType.entry : 'Unknown'
            event.linkURL = '/Comment/Edit/'
            event.entryUserName = this.updateUserName({ id: event.entryUserID, name: event.entryUserName }).name
            event.eventActors = [{ id: event.entryUserID, name: event.entryUserName, objectType: 'user' }]
            event.eventRelated = event.owners ? event.owners.filter((o) => { return o.ownerTypeID == 3 }).map((o) => ({ name: o.ownerName, objectType: 'person', id: o.ownerID })) : [];
            return event
        },
        store.prepareEmailEvent = function (event, obj) {
            event = this.prepareEvent(event, obj)

            var emailTypes = store.state.emails.emailTypes
            var emailType = _.find(emailTypes, function (c) { return (c.id != '' && (event.messageID.includes(c.id) || event.description.startsWith(c.id))) || (c.outbound != null && event.outbound == c.outbound) });

            event.name = emailType ? emailType.entry : 'Unknown'
            event.eventCategoryClass = emailType ? this.eventCategoryClass(emailType.icon) : this.eventCategoryClass('Unknown')
            event.eventCategory = emailType ? emailType.entry : 'Unknown'
            event.linkURL = ''
            event.entryUserName = this.updateUserName({ id: event.entryUserID, name: event.entryUserName }).name
            event.htmlBody = true;

            var owners = event.owners ? event.owners.filter((o) => { return o.ownerTypeID == 3 }).map((o) => ({ name: o.ownerName, objectType: o.ownerTypeID == 3 ? 'person' : 'company', id: o.ownerID })) : [];
            if (owners.length == 0) {
                if (!event.to)
                    event.to = "Unknown email";
                if (!event.from)
                    event.from = "Unknown email";

                //to fix
                if (event.outbound && event.to.includes(";")) {
                    var emailsTo = event.to.split(";");
                    event.to = emailsTo[0] + " and " + (emailsTo.length - 1) + " more";
                    event.description = event.description + " <br><i><b>Unknown:</b> " + emailsTo.join(", ") + "</i>";
                }

                //from fix
                if (!event.outbound && event.from.includes(";")) {
                    var emailsFrom = event.from.split(";");
                    event.from = emailsFrom[0] + " and " + (emailsFrom.length - 1) + " more";
                    event.description = event.description + " <br><i><b>Unknown:</b> " + emailsFrom.join(", ") + "</i>";
                }

                owners = event.outbound ? [{ name: "Unknown (" + event.to + ")", objectType: -1, id: -1 }] : [{ name: "Unknown (" + event.from + ")", objectType: -1, id: -1 }];
            }

            if (event.outbound) {
                //from
                event.eventActors = [{ id: event.entryUserID, name: event.entryUserName, objectType: 'user' }];
                //to
                event.eventRelated = owners;
            }
            else {
                //from
                event.eventActors = owners;
                //to
                event.eventRelated = [{ id: event.entryUserID, name: event.entryUserName, objectType: 'user' }];
            }
            
            return event
        },
        store.tagToFlagEvent = function (tag) {

            tag.description = ''
            tag.isTag = true

            //hashtags
            if (tag.name != 'atTag' && tag.values) {
                tag.name = tag.name.startsWith('#') ? tag.name : '#' + tag.name
                tag.type = 'hashtag'
                tag.values.forEach((val) => {
                    if (val.value)
                        tag.description = (tag.description ? tag.description + ', ' : tag.description) + (val.ownerID ? '@' + val.value + '' : val.value + '')
                })
                tag.tagValues = _.map(_.filter(tag.values, function (val) { return val.value }), function (val) { return { tagName: val.value, tagID: val.ownerID, tagType: val.ownerTypeID } });
            }
            //attags
            else if (tag.name == 'atTag' && tag.values && tag.values[0] && tag.values[0].ownerID) {
                tag.name = tag.values[0].value && tag.values[0].value.startsWith('@') ? tag.values[0].value : '@' + tag.values[0].value
                tag.type = 'attag'
                tag.tagID = tag.values[0].ownerID;
                tag.tagType = tag.values[0].ownerTypeID;
            }
            return tag
        },
        store.eventCategoryClass = function (category) {
            return category ? category.split(' ').join('-').replace('&', '').toLowerCase() : ''
        },
        store.eventsCanCombine = function (event, next_event, eventView) {
            //var sub_conditionCat = event.eventCategory == next_event.eventCategory
            var sub_conditionSameActor = event.eventActors.length > 0 && next_event.eventActors.length > 0 &&
                event.eventActors[0].id == next_event.eventActors[0].id
            var sub_conditionNoActor = event.eventActors.length == 0 && next_event.eventActors.length == 0
            var sub_conditionSameRel = event.eventRelationships && next_event.eventRelationships &&
                event.eventRelationships[0].id == next_event.eventRelationships[0].id
            var sub_conditionDate = Math.floor(Math.abs(new Date(event.date) - new Date(next_event.date)) / 1000 / 60)
            var conditionRules = event.combineEvent && next_event.combineEvent &&
                event.eventType == next_event.eventType

            //you have to specify this rule in order to use it
            if (eventView == 'session') {
                if (!event.combineEventReason || event.combineEventReason == 'session') {
                    //combine when both events were done by the same actor in a 30min period, regardless of category
                    var conditionSession = (sub_conditionSameActor || (sub_conditionNoActor && sub_conditionSameRel)) && sub_conditionDate < 60;
                    if (conditionSession)
                        return 'session';
                }
            }
            else if (eventView == 'category') {
                /* disabled category rule for now
                 * if (!event.combineEventReason || event.combineEventReason == 'category') {
                     //combine when both events have the same category, were done by the same actor, over a 60min period
                     var conditionCatActorDate = sub_conditionCat && (sub_conditionSameActor || sub_conditionNoActor) && sub_conditionDate < 60;
                     if (conditionCatActorDate || conditionRules)
                         return 'category';
                 }*/

                if (!event.combineEventReason || event.combineEventReason == 'event') {
                    var conditionEventRules = conditionRules && sub_conditionDate <= (43800 * 6);
                    if (conditionEventRules)
                        return 'event';
                }
            }

            return '';
        },
        store.groupEvents = function (opt) {// events, eventView 
            var eventsGrouped = []
            var sourceEvents = _.cloneDeep(opt.events)
            while (sourceEvents != null && sourceEvents.length > 0) {
                var event = sourceEvents.shift()
                var reprocessStack = []

                event.sub_events = []
                event.sub_actors = []
                event.combineEventReason = ""

                var combineEventName = event.name
                var combineEventCategoryClass = event.combineEventCategoryClass

                // find other events in the group
                comboEventLoop: while (sourceEvents != null && sourceEvents.length > 0) {
                    var next_event = sourceEvents.shift()
                    // not (session View and next event is a view)
                    if (opt.eventView != 'session' || !this.eventSettings.sessionIgnoreEvents.includes(next_event.eventType)) {
                        var reason = this.eventsCanCombine(event, next_event, opt.eventView)
                        if (reason) {
                            event.combineEventReason = reason

                            //add new sub event to the list
                            next_event.isSubEvent = true
                            event.sub_events.push(next_event)

                            //keep track of its sub actors
                            event.sub_actors = event.sub_actors.concat(next_event.eventActors)

                            //check if we'll need to change the collapsed name of our main event
                            if (event.name != next_event.name) {
                                if (event.combineEventReason == 'session') {
                                    if (event.weight * 1 > next_event.weight * 1 || (next_event.weight == event.weight && event.eventType * 1 > next_event.eventType * 1)) {
                                        combineEventName = next_event.combineEventName
                                        combineEventCategoryClass = next_event.combineEventCategoryClass
                                    }
                                }
                                if (event.combineEventReason == 'category')
                                    combineEventName = event.eventCategory
                            }
                        }
                        else if (opt.eventView != 'session') {
                            // only session view will skip over events
                            reprocessStack.push(next_event)
                            break comboEventLoop
                        }
                    }
                }

                event.combineEventName = combineEventName
                event.combineEventCategoryClass = combineEventCategoryClass

                event.sub_actors = _.remove(event.sub_actors, function (ev) { event.eventActors.includes(ev) })

                eventsGrouped.push(event)
                while (reprocessStack.length > 0) {
                    sourceEvents.splice(0, 0, reprocessStack.pop())
                }
            }
            return eventsGrouped
        },
        store.updateUserName = function (usr) {
            var userMap = store.state.search.searchMaps.maps.users
            if (usr && usr.id && userMap) {
                usr.name = userMap[usr.id] && userMap[usr.id] ? userMap[usr.id] : usr.name
            }
            return usr
        },
        store.parseOwnersToKeys = function (owners, active = true, activeField = 'active') {
            var keys = []
            var map = store.getters['search/objectTypeIdToStringMap']
            if (owners && map) {
                owners.forEach((o) => {
                    if (o[activeField] == active && map[o.ownerTypeID]) {
                        keys.push(map[o.ownerTypeID] + "::" + o.ownerID)
                    }
                })
            }
            return keys
        },
        store.capitalizeFirst = function (text) {
            return (text ? text.charAt(0).toUpperCase() + text.slice(1) : "")
        },
        store.eventSettings = {
            flagMap: { 128: 'Reminder', 256: 'Rated', 512: 'Research Notified', 1024: 'Shared', 2048: 'bookmark' },
            //commentTypes: { 1: "Contact Made", 2: "Message Left", 5: "General Info" },
            sessionIgnoreEvents: [37], // view event
            ignoreRelatedTypes: ['contact'],
            ignoredEvents: [37]
        },
        store.buildOwnerKey = function (opt) {
            if (!opt)
                return null
            var ownerKey = opt.ownerKey
            if (!ownerKey && opt.ownerID) {
                ownerKey = (opt.ownerType ? opt.ownerType : (opt.ownerID && this.state.search.searchMaps.maps.ownerTypeMap[opt.ownerTypeID] ? this.state.search.searchMaps.maps.ownerTypeMap[opt.ownerTypeID] : 'company'))
                    + '::' + opt.ownerID
            }
            return ownerKey
        },
        store.buildOwnerObject = function (opt) {
            if (!opt)
                return null
            if (opt.obj)
                return opt.obj
            var obj = { id: opt.ownerID, objectType: opt.ownerType ?? this.state.search.searchMaps.maps.ownerTypeMap[opt.ownerTypeID], objectKey: opt.ownerKey }
            if (!obj.objectKey)
                obj.objectKey = obj.objectType + '::' + obj.id
            return obj
        }
}

export {
    eventDataPrep
}
