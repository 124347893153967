<template>
    <li class="event-item event" :class="topClass">
        <span v-if="!minified" class="event-marker">
            <template v-if="subEventCount">
                <span class="sub-event-toggle">
                    {{ markerText }}
                </span>
            </template>
        </span>
        <i class="event-icon" :class="iconClass"></i>
        <span class="event-timestamp">
            <span class="day"> {{ obj.date ? (["comment", "event", "emaillog"].includes(obj.objectType) ?  toEventDate(obj.date, true) : toEventDate(obj.date)) : 'No Date' }}</span>
        </span>
        <span class="event-label">
            <template v-if="(canEdit || eventLink)">
                <a href="javascript:;" @click="this.labelClick(obj)">{{ eventLabel }}</a>
            </template>
            <template v-else>
                {{ eventLabel }}
            </template>
        </span>
        <template v-if="['comment', 'emaillog'].includes(obj.objectType) && obj.eventRelated && obj.eventRelated.length">
            @
            <template v-for="(er, erX) in obj.eventRelated" :key="erX">
                <a v-if="er.objectType && er.id > 0" :class="er.objectType + ' event-label'" :href="'/' + er.objectType +'/details/' + er.id" target="_blank">{{ er.name }}</a>
                <span v-else><b>{{ er.name }}</b></span>
                <span v-if="erX < obj.eventRelated.length - 1" class="dot-sep">&nbsp;<i class="fas fa-circle" style="font-size: .50em;">&nbsp;</i> </span>
            </template>
        </template>
        <span v-if="obj.eventActors && obj.eventActors.length > 0" class="event-details">
            by
            <template v-for="(actor, actID) in obj.eventActors" :key="actID">
                <a v-if="actor.objectType && actor.id > 0" :class="actor.objectType"
                   :href="'/' + actor.objectType + '/details/' + actor.id"
                   target="_blank">
                    {{ actor.name }}
                </a>
                <span style="color:#464163 !important" v-else>
                    {{ actor.name }}
                </span>&nbsp;
            </template>
        </span>
        <template v-if="!minified">
            <span v-if="hasSubEvents && !obj.eventActors && obj.sub_actors" class="sub-actor-list">
                by
                <template v-for="(sub_actors, act2ID) in ev.eventActors" :key="act2ID">
                    <a v-if="actor.objectType && actor.id > 0" :class="actor.objectType"
                       :href="'/' + actor.objectType + '/details' + actor.id"
                       target="_blank">
                        {{ actor.name }}
                    </a>
                    <span style="color:#464163 !important" v-else>
                        {{ actor.name }}
                    </span>&nbsp;
                </template>
            </span>
            <span v-if="canEdit">
                <a class="edit-comment" href="javascript:;" @click="this.$emit('editrequest', obj)">
                    <i class="fas fa-edit"></i>
                </a>
            </span>
            <ul v-if="obj.description || obj.eventRelated" class="event-info-preview">
                <li v-if="obj.description" class="event-description" @click="toggleIsOpen">
                    <template v-if="htmlDescription">
                        <span ref="htmlDesc" v-html="isOpen ? htmlDescription :  ellipseString(htmlDescription, 200, true, true)" @click="htmlClickHandler"></span>
                    </template>
                    <template v-else>
                        {{ isOpen ? obj.description : ellipseString(obj.description, 200, true) }}
                    </template>
                </li>
                <li v-if="obj.objectType != 'emaillog' && obj.eventRelated" class="event-related">
                    <span class="label"> </span>
                    <span class="data">
                        <template v-if="obj.objectType != 'comment'">
                            <template v-for="(er, erX) in obj.eventRelated" :key="erX">
                                <a :class="er.objectType" :href="'/' + er.objectType +'/details/' + er.id" target="_blank">{{ er.name }}</a>
                                <span v-if="erX < obj.eventRelated.length - 1" class="dot-sep">&nbsp;<i class="fas fa-circle" style="font-size: .50em;">&nbsp;</i> </span>
                            </template>
                        </template>
                    </span>
                </li>
            </ul>

            <ul v-if="isOpen && hasSubEvents" class="list-subevents">
                <timeline-event v-for="(subEvent, sidx) in obj.sub_events" :key="sidx" :obj="subEvent" @editrequest="this.$emit('editrequest', subEvent)" />
            </ul>
        </template>
    </li>
</template>


<script>
    import { inject } from 'vue'
    import $ from 'jquery'
    import { mapGetters } from 'vuex'
    import { isOpenSetup } from '../../../composables/isOpen.js'
    import _ from 'lodash'

    export default {
        name: 'TimelineEvent',
        props: {
            'obj': { type: Object },
            'eventView': { type: String, default: 'categoryview' },
            'minified': { type: Boolean, default: false },
        },
        emits: ['editrequest'],
        setup() {
            // tools
            const { isOpen, toggleIsOpen } = isOpenSetup()
            const emitter = inject("emitter")   // Inject event bus 

            return { isOpen, toggleIsOpen, emitter }
        },
        computed: {
            ...mapGetters({
                objectTypeIdToStringMap: 'search/objectTypeIdToStringMap',
            }),
            htmlDescription() {
                if (this.obj && (this.obj.htmlBody || this.obj.html)) {
                    var html = this.obj.htmlBody ? this.obj.description : this.obj.html;

                    //clean up paragraphs and add pointers
                    var $descHtml = $("<div>").html(html);
                    $descHtml.find("p").each(function () { $(this).replaceWith(this.childNodes); });
                    $descHtml.find(".cmdmTag").each(function () { $(this).css('cursor', 'pointer'); });

                    return $descHtml.html();
                }
                return null;
            },
            markerText() { return (this.isOpen ? '-' : this.subEventCount) },
            hasSubEvents() {
                return this.obj.sub_events
            },
            topClass() {
                return (this.isOpen ? 'is-open ' : ' ') + this.obj.eventCategoryClass + (this.obj.sub_events ? ' event-group ' : '')
                    + (!this.obj.isSubEvent ? ' list-item ' : ' subevent')
            },
            iconClass() {
                return (this.isOpen ? this.obj.eventCategoryClass : this.obj.combineEventCategoryClass)
            },
            subEventCount() {
                return this.hasSubEvents ? this.obj.sub_events.length + 1 : 0
            },
            eventLabel() {
                return this.obj.combineEventReason ? this.obj.combineEventName : this.obj.name
            },
            canEdit() {
                return (this.obj.canEdit || this.obj.objectType == 'comment');
            },
            eventLink() {
                if (this.obj && this.obj.linkURL)
                    return this.obj.linkURL + (this.obj.id > 0 ? this.obj.id : "")
                else
                    return null;
            },
            openClass() {
                return this.isOpen ? 'expanded ' : 'collapsed '
            }
        },
        methods: {
            labelClick() {
                if (this.canEdit) {
                    this.$emit('editrequest', this.obj)
                }
                else if (this.obj && this.obj.tagValues && this.obj.tagValues.length > 0) {
                    let tagObj = Object.assign({ name: this.obj.name }, this.obj.tagValues[0]);
                    this.emitter.emit('applyFilter', { filter: 'tags', 'filterVal': JSON.stringify([tagObj]) })
                }
                else if (this.eventLink) {
                    window.open(this.eventLink, '_blank')
                }
            },
            htmlClickHandler(e) {
                //only handle the click for tags
                if (e.target && e.target.classList && e.target.classList.contains('cmdmTag')) {
                    var tag = e.target;

                    //# tag
                    if (tag.classList.contains('cmdmHashTag') && tag.dataset && tag.dataset['tagname']) {
                        e.preventDefault();
                        e.stopPropagation();

                        var tagObj = { name: tag.dataset['tagname'] };
                        tagObj.values = [];

                        //form the values
                        //this will turn a tag structure such as { 'tagname', 'tagvalue', 'tagvalue1', 'tagvalue2' } into ['' ,'1', '2']
                        var iterator = [...new Set(_.map(Object.keys(tag.dataset), function (key) { return key.replace('tagname', "").replace('tagvalue', "").replace('tagid', "").replace('tagtype', "") }))];
                        _.each(iterator, function (index) {
                            var tagVal = {};
                            if (tag.dataset['tagvalue' + index]) tagVal.value = tag.dataset['tagvalue' + index];
                            if (tag.dataset['tagid' + index]) tagVal.ownerID = tag.dataset['tagid' + index];
                            if (tag.dataset['tagtype' + index]) tagVal.ownerTypeID = tag.dataset['tagtype' + index];

                            if (tagVal.value) tagObj.values.push(tagVal)
                        })

                        window.open("/search?docType=Company&tags=" + encodeURIComponent(JSON.stringify(tagObj)));
                    }
                    //@ tag
                    if (tag.classList.contains('cmdmAtTag') && tag.dataset && tag.dataset['tagtype'] && tag.dataset['tagid']) {
                        e.preventDefault();
                        e.stopPropagation();

                        window.open("/" + (this.objectTypeIdToStringMap[tag.dataset['tagtype']] ? this.objectTypeIdToStringMap[tag.dataset['tagtype']] : 'company') + "/Details/" + tag.dataset['tagid'])
                    }
                }
            },
        }
    }
</script>

<style lang="scss" scoped>
    .cmdmTag {
        cursor: pointer !important;
    }
    li.event-item {
        display: block;
    }
    li.event.is-open .event-icon,
    li.event.is-open .event-timestamp,
    li.event.is-open .event-label,
    li.event.is-open .event-details,
    li.event.is-open .sub-actor-list,
    li.event.is-open .edit-comment,
    li.event.is-open .event-info-preview {
        font-size: 1.15em !important;
    }


</style>