<template>
    <template v-if="showEvents">
        <ul v-if="showCategorySelector" class="menu-horizontal event-categories cat-filters">
            <template v-for="cat in eventCategoryCounts" :key="cat.category">
                <li class="event-item category" :class="cat.categoryClass" :title="cat.category" @click="selectCategory(cat.category)">
                    <span class="event-icon"></span>
                    <span class="event-label">{{ cat.category }}</span>
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" :style="cat.style" :aria-valuenow="cat.percentage" aria-valuemin="0" aria-valuemax="100"></div>
                        <div class="progress-label"> {{ cat.percentage }}</div>
                    </div>
                </li>
            </template>
        </ul>
        <ul class="list-events" :class=" minified ? 'minified' :'' ">
            <timeline-event v-for="(ev, evIdx) in eventsToRender.events" :key="evIdx" :obj="ev" @editrequest="this.$emit('editrequest', $event)" :minified="minified" />
        </ul>
        <button v-if="showMoreButton" class="button-secondary small margin-vertical-xsmall" @click="showMore(moreEvents)" :title="moreEvents + ' more events'">More</button>
        <button v-if="showSearchButton" class="button-secondary small margin-vertical-xsmall" @click="showMore(0)" :title="'Search for more events'"><i class="fas fa-external-link-alt"></i>More</button>
    </template>
</template>

<script>
    import { ref, computed, onMounted } from 'vue'
    import _ from 'lodash'
    import { useStore } from 'vuex'
    import { getObjectTools } from '../../composables/ObjectTools.js'

    import TimelineEvent from '../ui/timeline/TimelineEvent.vue'

    export default {
        name: 'RecordEvents',
        components: { TimelineEvent },
        props: {
            obj: { type: Object },
            eventSource: { type: String, default: 'events' },
            criteria: { type: Object },
            searchKey: { type: String },
            minified: { type: Boolean, default: false },
            renderLimit: { type: Number, default: 0 },
            eventView: { type: String, default: 'category' },
            loadMore: { type: Boolean, default: true },
            ignoredEvents: { type: Array },
            parentVisible: { type: Boolean, default: false },
            insertedEvents: { type: Array, default: () => [] },
            recordContext: { type: String, default: 'details' },
            initialCategory: { type: String },
            events: { type: Array },
        },
        emits: ['editrequest', 'eventsloaded'],
        setup(props) {
            var store = useStore()

            // data
            const shownLimit = ref(null)
            const fetching = ref(false)
            const fetchedResult = ref({ 'fetched': null, 'total': null, 'complete': false })
            const selectedCategory = ref(props.initialCategory ? _.cloneDeep(props.initialCategory) : null)

            // const
            const { objectID, objectType, objectKey, storeKey } = getObjectTools(props)
            const storeActions = objectType.value == 'emaillog' ? { 'emails': 'emails/fetchEmails' } : { 'events': 'eventLog/fetchEvents','eventSearch': 'eventLog/searchEvents'}

            // computed
            const eventTypeRenderMap = computed(() => { return store.state.search.eventTypeRenderMap })
            const eventLog = computed(() => store.getters['eventLog/eventLog'])
            const commentLog = computed(() => store.getters['comments/commentLog'])
            const emailLog = computed(() => store.getters['emails/emailLog'])
            const tagLog = computed(() => store.getters['comments/tagLog'])
            const acqLog = computed(() => store.getters['acquisitions/acqLog'])
            const articleLog = computed(() => store.getters['acquisitions/articleLog'])
            const stores = computed(() => {
                return {
                    'events': eventLog.value,
                    'eventSearch': eventLog.value,
                    'comments': commentLog.value,
                    'emails' : emailLog.value,
                    'acquisitions': acqLog.value,
                    'articles': articleLog.value,
                    'tagsflags': tagLog.value
                }
            })
            const queryKey = computed(() => {
                var qKey = props.searchKey
                if (!qKey && props.criteria && Object.keys(props.criteria).length) {
                    qKey = objectKey.value + '__' + Object.keys(props.criteria).join('__')
                }
                return objectKey.value.replace('::', '__') + '__' + qKey
            })
            const hasKey = computed(() => {
                var goodKey = false
                switch (props.eventSource) {
                    case 'events':
                    case 'comments':
                    case 'emails':
                        goodKey = objectKey.value 
                        break
                    case 'eventSearch':
                        goodKey = queryKey.value
                        break
                    case 'acquisitions':
                        goodKey = objectID.value && objectType.value == 'company'
                        break
                    case 'articles':
                        goodKey = objectID.value && objectType.value == 'acquisition'
                        break
                    case 'tagsflags':
                        goodKey = props.obj && (props.obj.users || props.obj.tags)
                        break
                    default:
                        goodKey = props.events
                        break;
                }
                return goodKey
            })
           
            // methods
            const eventCategoryClass = (category) => {
                return category ? category.split(' ').join('-').replace('&', '').toLowerCase() : ''
            }

            onMounted(() => {
                if (shownLimit.value == null && props.renderLimit > 0)
                    shownLimit.value = props.renderLimit
            })

            return {
                // data
                shownLimit, fetching, fetchedResult, selectedCategory, 
                // const
                storeActions, 
                // computed
                objectType, objectKey, storeKey, queryKey, eventTypeRenderMap, stores,
                hasKey,
                // Methods
                eventCategoryClass
            }
        },
        computed: {           
            showEvents() {
                return this.hasKey && this.eventData.total > 0
            },
            eventSourceLimit() {
                if (this.obj && this.obj[this.eventSource] && _.isArray(this.obj[this.eventSource]))
                    return this.obj[this.eventSource].length
                else return 0;
            },
            eventData() {
                var eventLogState = { 'total': 0, 'events': [], 'ownerKey': this.objectKey, 'preparedEvents': [], 'groupedEvents': [], 'sessionEvents': [], 'fetched': false }

                if (this.hasKey && this.parentVisible) {
                    var storeKey = this.eventSource == 'eventSearch' ? this.queryKey : this.storeKey;
                    if (this.stores[this.eventSource] && this.stores[this.eventSource][storeKey]) 
                        return this.stores[this.eventSource][storeKey]

                    if (this.events) {
                        eventLogState.preparedEvents = this.events;
                        eventLogState.fetched = true;
                        eventLogState.total = eventLogState.preparedEvents.length;
                        return eventLogState;
                    }

                    if (!this.fetchedResult.complete)
                        this.fetchEvents()
                }
                return eventLogState
            },
            eventsToRender() {
                var events = []
                if (this.eventData) {
                    if (this.eventView == 'ungrouped' && this.eventData.preparedEvents) {
                        events = this.eventData.preparedEvents
                    }

                    else if (this.eventView == 'session' && this.eventData.sessionEvents) {
                        events = this.eventData.sessionEvents
                    }
                    //else if (this.eventData.groupedEvents && this.eventSource == 'events') {
                    //  events = this.eventData.groupedEvents.filter((ev) => { return !this.selectedCategory || ev.eventCategory == this.selectedCategory })
                    //}
                    else if (this.eventSource == 'events' && this.eventData.preparedEvents) {
                        events = this.eventData.preparedEvents.filter((ev) => { return !this.selectedCategory || ev.eventCategory == this.selectedCategory })
                    }

                    else if (this.eventData.preparedEvents) {
                        events = this.eventData.preparedEvents.filter((ev) => { return !this.selectedCategory || ev.eventCategory == this.selectedCategory })
                    }

                    var total = events.length
                    events = events.slice(0, this.eventRenderLimit)

                }
                return { total: total, events: events }
            },
            moreEvents() {
                return Math.max(this.eventsToRender.total - this.eventsToRender.events.length, 0)
            },
            eventRenderLimit() {
               if (this.shownLimit)
                    return this.shownLimit

                var limit = 5
                switch (this.recordContext) {
                    case 'sidebar':
                    case 'detailspage':
                        limit = 10
                        if (this.eventSource == 'comments')
                            limit = 5
                        break;
                    case 'pin-card':
                    case 'details':
                    case 'brands':
                    case 'table':
                        limit = 1
                        break;
                }
                return limit
            },
            eventCount() {
                if ((this.eventData && this.eventData.events) || this.insertedEvents)
                    return ((this.eventData && this.eventData.events) ? this.eventData.events.length : 0) + (this.insertedEvents ? this.insertedEvents.length : 0)
                return 0;
            },
            eventCategoryClasses() {
                var catClasses = {};
                _.each(this.eventData.preparedEvents, function (e) { catClasses[e.eventCategory] = e.eventCategoryClass })
                return catClasses;
            },
            eventCategoryCounts() {
                if (!(this.eventData && this.eventData.preparedEvents && this.eventData.preparedEvents.length > 0))
                    return null
                var counts = _.countBy(this.eventData.preparedEvents, 'eventCategory');
                var categoryCounts = Object.keys(counts).map((key) => {
                    return {
                        category: key,
                        count: counts[key],
                        categoryClass: this.eventCategoryClasses[key] + (key == this.selectedCategory ? ' is-on' : ' is-off'),
                        percentage: ((counts[key] / this.eventCount) * 100).toFixed(0) + '%',
                        style: 'width: ' + ((counts[key] / this.eventCount) * 100).toFixed(0) + '%'
                    }
                })
                return categoryCounts
            },
            showCategorySelector() {
                return !this.minified && this.eventView == 'category' && ['events', 'comments'].includes('events') && this.eventCategoryCounts && this.eventCategoryCounts.length > 1
            },
            showMoreButton() {
                return this.loadMore && !this.minified && this.moreEvents
            },
            showSearchButton() {
                return !this.showMoreButton && this.loadMore && !this.minified && this.eventSource == 'events'
            },
        },
        methods: {
            fetchEvents() {
                if (this.eventView == 'session' || this.fetching || this.events)
                    return // don't execute query for session events they will come in with the events, or if we're already fetching, or if we supplied the events to the component directly

                if (this.objectKey && this.storeActions[this.eventSource]) {
                    //fix the default params
                    var limit = _.max([this.eventSourceLimit, this.eventRenderLimit * 3]);

                    var opt = { 'obj': this.obj, 'limit': limit, 'fetch': true, 'noViewed': this.obj && this.obj.objectType != 'event' ? true : false, 'criteria': this.criteria, 'queryKey': this.queryKey, 'insertedEvents': this.insertedEvents }
                    var action = this.storeActions[this.eventSource];
                    this.fetching = true
                    this.$store.dispatch(action, opt).then((results) => {
                        if (results) {
                            results.complete = true;
                            this.fetchedResult = results
                        }
                        this.fetching = false

                    }).catch(error => {
                        if (error.fatal) {
                            this.fetchedResult = { 'fetched': true, 'total': 0, 'complete': true };
                            this.$toast.add({ severity: 'error', summary: 'Error loading event data', detail: error.message, life: 3000 })
                        }
                        this.fetchedResult.complete = true;
                        this.fetching = false
                    })
                }
            },
            selectCategory(category) {
                if (this.selectedCategory == category)
                    this.selectedCategory = null
                else
                    this.selectedCategory = category
            },
            showMore(iMoreEvents) {
                if (iMoreEvents <= 0 && this.eventsToRender.total > 0) {
                    window.open('/search?docType=Event&ownerKeys=' + encodeURIComponent(JSON.stringify([this.objectKey])))
                }
                else {
                    if (this.shownLimit == null) this.shownLimit = this.eventRenderLimit;
                    this.shownLimit += (this.eventsToRender.total/3)
                }

            }
        },
       
        watch: {
            obj() {
                this.fetchedResult = { 'fetched': null, 'total': null, 'complete': false }
            },
            eventCount() {
                this.$emit('eventsloaded', this.eventCount)
            }
        }
    }
</script>

<style lang="scss">
    .list-events.minified {
        margin: 0 !important;
        padding: 0 !important;
    }
</style>